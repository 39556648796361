export default class RegFlowStep {
  constructor(name, order, navigationGuard) {
    this.name = name;
    this.order = order;
    this.navigationGuard = navigationGuard;
  }
  isCurrent(route) {
    return this.getAssociatedRouteName() == route.name;
  }
  isComplete(route, abandonedCart) {
    switch (this.name) {
      case "signup": {
        return route.name !== "onboarding-signup";
      }
      case "plans": {
        return (
          route.name !== "onboarding-plans" &&
          route.name !== "onboarding-signup" &&
          abandonedCart.hasPlan()
        );
      }
      case "menu": {
        return route.name === "onboarding-checkout";
      }
      default:
        return false;
    }
  }
  isUpcoming(route) {
    switch (this.name) {
      case "plans": {
        return route.name === "onboarding-signup";
      }
      case "menu": {
        return ["onboarding-signup", "plans"].indexOf(route.name) > -1;
      }
      case "checkout": {
        return route.name !== "onboarding-checkout";
      }
      default:
        return false;
    }
  }
  navigate(router) {
    const current = router.currentRoute.value.name;
    const next = this.getAssociatedRouteName();
    if (
      next !== current &&
      (this.navigationGuard ? this.navigationGuard() : true)
    ) {
      return navigateTo({
        name: next,
        query: router.currentRoute.value.query,
      })
    }
  }
  getAssociatedRouteName() {
    const mapping = {
      signup: "onboarding-signup",
      plans: "onboarding-plans",
      menu: "menus",
      checkout: "onboarding-checkout",
    };
    return mapping[this.name];
  }
}
